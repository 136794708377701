.root {
  position: relative;
  width: 100%;
  height: 100%;
}

.loader {
  position: absolute;
  inset: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}
