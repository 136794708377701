@import "https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;600;700;800;900&display=swap";

body {
  font-family: Nunito Sans, sans-serif;
  background-color: var(--base-color);
}

#root {
  width: 100%;
  min-height: var(--full-height);
  min-height: 100vh;
  height: 100%;
  flex: 1;
}

* {
  font-family: Nunito Sans, sans-serif;
  box-sizing: border-box;
}
