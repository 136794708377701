.root {
  font-weight: 400;
  font-size: 14px;
  line-height: 120%;

  &.primary {
    color: var(--gray-default-color);
  }

  &.secondary {
    color: var(--blue-light-color);
  }
}
